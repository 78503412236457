import { Injectable } from '@angular/core';
import axios from 'axios';

import { LoadingController, ToastController } from '@ionic/angular';

import { Storage } from '@ionic/storage';
import * as CryptoJS from 'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class StateService {

  Entity: any = [];
  CEO: any = {
    SameCFO: false,
    SameSecretary: false,
    SameDirector: false,
  };
  CFO: any = {};
  Secretary: any = {};
  Directors: any = [];
  OtherPersons: any = [];
  isOtherPerson: any = false;
  Info: any = {};
  AddedInfo: any = {};

  isLoading: any = "";
  loading: any;
  Loading: Boolean = true;

  // URL = "https://efilebusiness-search-backend.herokuapp.com";
  URL = "https://5qgstng9il.execute-api.us-west-1.amazonaws.com/dev";
  // URL = "http://localhost:3000";

  constructor(
    public loadingController: LoadingController,
    public toastController: ToastController,
    private storage: Storage,
  ) { }

  async decryptObject(data) {
    let getoriginaltext = await data.toString();
    var decrypted = CryptoJS.AES.decrypt(getoriginaltext, '=A[W%Yz<joV*3_Y)')
    data = CryptoJS.enc.Utf8.stringify(decrypted)
    let object = JSON.parse(data)
    return object
  }

  updateAddress(entity) {
    if (entity.Jurisdiction === 'NJ' && entity.AddedOn === '09/08/22') {
      var regex = /\d{4}/g;
      let obj = {
        ...entity,
        AgentForServiceOfProcessName: entity.AgentForServiceOfProcessName && entity.AgentForServiceOfProcessName.trim() ? entity.AgentForServiceOfProcessName.trim() : 'NA',
        EntityAddress : entity.EntityAddress || entity.EntityMailingAddress || entity.EntityMailingAddress.trim() || entity.EntityAddress.trim() ? entity.EntityAddress.trim() || entity.EntityMailingAddress.trim() : '',
        EntityMailingAddress : entity.EntityAddress || entity.EntityMailingAddress || entity.EntityMailingAddress.trim() || entity.EntityAddress.trim() ? entity.EntityAddress.trim() || entity.EntityMailingAddress.trim() : '',
        AgentForServiceOfProcessAddress : entity.AgentForServiceOfProcessAddress === '' || entity.AgentForServiceOfProcessAddress === 'NA' || entity.AgentForServiceOfProcessAddress === 'N/A' ? entity.EntityAddress || entity.EntityMailingAddress : entity.AgentForServiceOfProcessAddress || '',
      }
      let keystocheck = ['EntityAddress', 'EntityMailingAddress', 'AgentForServiceOfProcessAddress']
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && keystocheck.includes(key)) {
          let numbers = obj[key].match(regex);
          console.log("old obj[key]",obj[key]);
          if (numbers && numbers.length > 0) {
            numbers.forEach(number => {
              obj[key] = obj[key].replaceAll(number.toString(), "NJ 0"+number)
            });
            console.log("new obj[key]",obj[key]);
            
          }
        }
      }
      return obj
    } else {
      return {
        ...entity,
        AgentForServiceOfProcessName: entity.AgentForServiceOfProcessName && entity.AgentForServiceOfProcessName.trim() ? entity.AgentForServiceOfProcessName.trim() : 'NA',
        EntityAddress : entity.EntityAddress || entity.EntityMailingAddress || entity.EntityMailingAddress.trim() || entity.EntityAddress.trim() ? entity.EntityAddress.trim() || entity.EntityMailingAddress.trim() : '',
        EntityMailingAddress : entity.EntityAddress || entity.EntityMailingAddress || entity.EntityMailingAddress.trim() || entity.EntityAddress.trim() ? entity.EntityAddress.trim() || entity.EntityMailingAddress.trim() : '',
        AgentForServiceOfProcessAddress : entity.AgentForServiceOfProcessAddress === '' || entity.AgentForServiceOfProcessAddress === 'NA' || entity.AgentForServiceOfProcessAddress === 'N/A' ? entity.EntityAddress || entity.EntityMailingAddress : entity.AgentForServiceOfProcessAddress || '',
      }
    }
  }

  GetData(term) {
    return new Promise(resolve => {
      axios.get(this.URL + "/searchByNumber?term=" + term).then(async (res) => {
        this.Entity = await this.decryptObject(res.data)
        this.Entity.EntityName = this.Entity.EntityName.split("\n").pop();
        // this.Entity.EntityType = "";
        
        if (this.Entity.CEOfirstName) {
          this.CEO.FirstName = this.Entity.CEOfirstName.trim()
        }
        if (this.Entity.CEOmiddlename) {
          this.CEO.MiddleName = this.Entity.CEOmiddlename.trim()
        }
        if (this.Entity.CEOlastname) {
          this.CEO.LastName = this.Entity.CEOlastname.trim()
        }
        this.Entity = this.updateAddress(this.Entity);
        console.log("Entity",this.Entity)
        this.storage.set("entity", this.Entity).then((res) => {
        })
        if (res) {
          this.Loading = false;
          resolve(true)
        } else {
          resolve(false)
        }
      })
    })
  }

  AddData() {
    return new Promise(resolve => {

      let payload = {
        EntityName: this.Entity.EntityName,
        EntityNumber: this.Entity.EntityNumber,
        Jurisdiction: this.Entity.Jurisdiction,
        RegistrationDate: this.Entity.RegistrationDate,
        Status: this.Entity.Status,
        AgentForServiceOfProcessName: this.Entity.AgentForServiceOfProcessName,
        EntityType: this.Entity.EntityType,
        AgentForServiceOfProcessAddress: this.Entity.AgentForServiceOfProcessAddress == 'NA' ? this.Entity.EntityAddress : this.Entity.AgentForServiceOfProcessAddress ? this.Entity.AgentForServiceOfProcessAddress : this.Entity.EntityAddress,
        EntityAddress: this.Entity.EntityAddress,
        EntityMailingAddress: this.Entity.EntityMailingAddress || this.Entity.EntityAddress,
        Persons: {
          CEO: this.CEO,
          CFO: this.CFO,
          Secretary: this.Secretary,
          Directors: this.Directors,
          OtherPersons: this.OtherPersons
        }
      }



      axios.post(this.URL + "/data/addinformation", payload).then((res) => {
        if (res) {
          this.AddedInfo = res.data.addinformation;
          resolve(true)
        } else {
          resolve(false)
        }
      })
    })
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      keyboardClose: true,
      position: 'top',
    });
    toast.present();
  }

  async presentLoading(msg) {
    this.isLoading = true;
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: msg,
    });
    this.loading.present().then((res) => {
      if (!this.isLoading) {
        this.isLoading = false;
        this.loading.dismiss();
      }
    });
  }

  HideLoading() {
    this.isLoading = false;
    this.loading.dismiss();
  }
}
